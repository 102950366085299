import {
  Form,
  Link,
  Outlet,
  useRouteLoaderData,
  useSubmit,
} from '@remix-run/react';
import { MessagesSquare } from 'lucide-react';
import { useRef } from 'react';
import { FeedbackDialog } from '#app/components/feedback-dialog.tsx';
import { ThemeSwitch } from '#app/components/theme-switch';
import { Button } from '#app/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu.tsx';
import { Icon } from '#app/components/ui/icon.tsx';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '#app/components/ui/tooltip.tsx';
import { type loader as rootLoader } from '#app/root.tsx';
import { getUserImgSrc } from '#app/utils/misc';
import { useOptionalUser, useUser, userIsPaying } from '#app/utils/user';

export default function AppLayout() {
  const data = useRouteLoaderData<typeof rootLoader>('root');
  const user = useOptionalUser();
  const isUserPaying = userIsPaying(user);

  // TODO: Add add search bar functionality or remove it later
  // const matches = useMatches()
  // const isOnSearchPage = matches.find(m => m.id === 'routes/users+/index')
  // const searchBar = isOnSearchPage ? null : <SearchBar status="idle" />
  const searchBar = null;

  return (
    <div className="flex h-screen flex-col justify-between">
      <header className="container py-6">
        <nav className="flex flex-wrap items-center justify-between gap-4 sm:flex-nowrap md:gap-8">
          <Logo />
          <div className="ml-auto hidden max-w-sm flex-1 sm:block">
            {searchBar}
          </div>
          <div className="flex items-center gap-3">
            {!isUserPaying ? (
              <Button asChild variant="link" className="text-muted-foreground">
                <Link to="/pricing">Pricing</Link>
              </Button>
            ) : null}
            {user ? (
              <UserDropdown />
            ) : (
              <Button asChild variant="default" size="pill">
                <Link to="/login">Log In</Link>
              </Button>
            )}
          </div>
          <div className="block w-full sm:hidden">{searchBar}</div>
        </nav>
      </header>
      <div className="flex-1">
        <Outlet />
      </div>
      <div className="container flex items-center justify-between pb-5">
        <FeedbackDialog
          trigger={
            <Button variant="ghost" size="icon" aria-label="Feedback">
              <Tooltip>
                <TooltipTrigger asChild>
                  <MessagesSquare />
                </TooltipTrigger>
                <TooltipContent side="right" sideOffset={5}>
                  Feedback
                </TooltipContent>
              </Tooltip>
            </Button>
          }
        />

        <div className="flex items-center justify-end gap-4">
          <Button asChild variant="link" className="text-muted-foreground">
            <Link to="/tos">Terms & Conditions</Link>
          </Button>

          <Button asChild variant="link" className="text-muted-foreground">
            <Link to="/privacy">Privacy</Link>
          </Button>

          <ThemeSwitch userPreference={data?.requestInfo.userPrefs.theme} />
        </div>
      </div>
    </div>
  );
}

function Logo() {
  return (
    <Link to="/" className="group grid leading-snug">
      <span className="font-showcase text-lg font-bold transition group-hover:-translate-y-1">
        Kempo
      </span>
    </Link>
  );
}

function UserDropdown() {
  const user = useUser();
  const submit = useSubmit();
  const formRef = useRef<HTMLFormElement>(null);
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button asChild variant="secondary">
          <Link
            to="me"
            // this is for progressive enhancement
            onClick={(e) => e.preventDefault()}
            className="flex items-center gap-2"
          >
            <img
              className="h-8 w-8 rounded-full object-cover"
              alt={user.name ?? user.username}
              src={getUserImgSrc()}
            />
            <span className="text-body-sm font-bold">
              {user.name ?? user.username}
            </span>
          </Link>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <DropdownMenuContent sideOffset={8} align="start">
          <DropdownMenuItem asChild>
            <Link prefetch="intent" to="/me">
              <Icon className="text-body-md" name="avatar">
                Profile
              </Icon>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem asChild>
            <Link prefetch="intent" to="dashboard">
              <Icon className="text-body-md" name="file-text">
                Dashboard
              </Icon>
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem
            asChild
            // this prevents the menu from closing before the form submission is completed
            onSelect={(event) => {
              event.preventDefault();
              submit(formRef.current);
            }}
          >
            <Form action="/logout" method="POST" ref={formRef}>
              <Icon className="text-body-md" name="exit">
                <button type="submit">Logout</button>
              </Icon>
            </Form>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
}
